@mixin dialog-window {
  @keyframes dialog-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .dialog {
    //needs to compete with CKEditor
    z-index: 20000;
    @include typography(text-medium);
    position: fixed;
    top: 50%;
    left: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - #{$gutter * 2});
    max-height: calc(100vh - #{$gutter * 2});
    // width: -moz-fit-content;
    width: auto;
    height: auto;
    border-top: 4px solid $primary-color;
    background-color: $white;
    color: $text-color-regular;
    border-radius: .125rem;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    transform: translate(-50%, -50%);
    &:not(.fading-out) {
      animation: dialog-fade-in .3s ease both;
    }
    @supports (width: fit-content) {
      transform: none;
      width: fit-content;
      height: fit-content;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .dialog-title {
    background-color: $dark-gray;
    color: $text-color-white;
    padding: $baseline $baseline * 2;
    padding-right: calc(#{$baseline} + 7rem);
    flex-shrink: 0;
    &:empty {
      display: none;
    }
  }
  .dialog-close {
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: $baseline / 2;
    right: $baseline;
    color: $anchor-color;
    line-height: 1.5;
    transition: none;
    &::before {
      @include sb-icon-close($anchor-color);
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: .4em;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    &:hover,
    &:focus {
      color: $anchor-color-hover;
      &::before {
        @include sb-icon-close($anchor-color-hover);
      }
    }
  }
  .dialog-title ~ .dialog-close {
    top: $baseline;
    right: $baseline * 2;
    color: $text-color-white;
    &::before {
      @include sb-icon-close($white);
    }
    &:hover,
    &:focus {
      color: $anchor-color;
      &::before {
        @include sb-icon-close($anchor-color);
      }
    }
  }
  .dialog-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: $baseline * 2 $baseline * 2;
    background-color: $white;
  }
  .dialog-title ~ .dialog-body {
    padding-top: 0;
  }
  .dialog-underlay {
    z-index: 19999;
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($off-white, .8);
    &:not(.fading-out) {
      animation: dialog-fade-in .3s ease both;
    }
  }
}
@mixin dialog-full {
  width: 100%;
  height: 100%;
  .dialog-body {
    position: relative;
  }
}
@mixin dialog-positioned {
  position: absolute;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  transform: none;
  max-height: none;
  border: none;
  &::after {
    content: '';
    display: none;
    position: absolute;
    width: (20/$em) * 1rem;
    height: (20/$em) * 1rem;
    border: (10/$em) * 1rem solid $primary-color;
    border-right-color: transparent;
    border-bottom-color: transparent;
    transform: translateX(-50%) rotate(-45deg);
    pointer-events: none;
  }
  &.right-top,
  &.right-bottom {
    border-left: 3px solid $primary-color;
    &::after {
      display: block;
      left: 0;
      top: (10/$em) * 1rem;
    }
  }
  &.left-top,
  &.left-bottom {
    border-right: 3px solid $primary-color;
    &::after {
      display: block;
      right: 0;
      top: (10/$em) * 1rem;
      transform: translateX(50%) rotate(135deg);
    }
  }
  &.right-bottom,
  &.left-bottom {
    &::after {
      top: auto;
      bottom: (10/$em) * 1rem;
    }
  }
  .tooltip-stem {
    display: none;
  }
}
@mixin dialog-tooltip {
  padding: $baseline $baseline * 1.5;
  max-width: (300/$em) * 1rem;
  dl {
      margin: 0;
  }
  dd,
  dt {
      display: block;
      margin: 0;
      font-size: 1rem;
  }
  dt {
      font-weight: $bold;
      margin-top: $baseline;
  }
}
@mixin context-menu {
  @include typography(text-medium);
  color: $text-color-regular;
  background-color: $white;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
  min-width: 8rem;
  overflow: hidden;
  .context-menu-title {
    @include typography(header-e);
    padding: $baseline * 2/3 $baseline $baseline / 2;
  }
  ul {
    @include unstyled-list;
  }
  li {
    white-space: nowrap;
    cursor: pointer;
    a {
      margin: $baseline / -2 $baseline * -1;
    }
    & + li {
      border-top: 1px solid $light-gray;
    }
  }
  li,
  a {
    color: inherit;
    display: block;
    padding: $baseline / 2 $baseline;
    &:hover,
    &:focus {
      color: inherit;
      background-color: $off-white;
    }
  }
}
@mixin dialog-configure {
  .sb-configure-form {
    grid-template-columns: repeat(2, minmax(15rem, 1fr));
    grid-column-gap: $gutter * 2;
    @include breakpoint(600px up) {
      display: grid;
    }
    .input-html,
    .label-after-inline {
      grid-column-end: span 2;
    }
    .sb-section {
      margin-top: $baseline;
    }
  }
  .sb-configure-header {
    grid-column-end: span 2;
  }
}

@mixin inline-edit(
  $edit-border-color: inherit,
  $edit-overlay-color: rgba(128, 128, 128, 0.1),
  $transition-delay: .6s,
  $transition-duration: .2s
) {
  $easeout: cubic-bezier(0, 1, 0, 1);
  $easein: cubic-bezier(1, 0, 1, 0);
  %edit-overlay {
    z-index: 15;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // background-color: transparent;
    // border: 1px dashed transparent;
    border-color: transparent;
    transition: border-color $transition-duration ease;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .5;
      border: 2px dashed;
      border-color: inherit;
    }
  }
  %edit-overlay-on {
    border-color: $edit-border-color;
    // background-color: $edit-overlay-color;
    &::before {
      transition: opacity $transition-duration ease;
      opacity: 1;
    }
  }

  .sb-inline-editor,
  .sb-display-point-edit,
  .sb-display-point-first {
    min-height: calc(#{$baseline * 2.5} + 2px);
    position: relative;
    pointer-events: auto !important;
    transition: outline-color $transition-duration ease;
  }

  .sb-inline-type-rte.no-content,
  .sb-display-point-first {
    min-height: $baseline * 8;
  }

  .sb-display-point-edit {
    //prevent margin collapse for accurate overlay position
    padding: .1px;
  }
  .cke_editable {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      color: inherit;
  }

  .sb-inline-editor-overlay,
  .sb-display-point-edit-overlay {
    @extend %edit-overlay;
    pointer-events: none;
  }
  .sb-inline-editor:hover .sb-inline-editor-overlay,
  .sb-display-point-edit:hover .sb-display-point-edit-overlay {
    @extend %edit-overlay-on;
  }

  .sb-display-point-edit-buttons {
    @include typography(text-medium);    
    z-index: 16;
    display: flex;
    flex-wrap: wrap;
    visibility: hidden;
    opacity: 0;
    font-style: normal;
    line-height: $baseline * 1.5;
    position: absolute;
    top: 1px;
    right: 1px;
    padding-left: $baseline / 2;
    padding-right: $baseline / 2;
    background-color: $dark-gray;
    //when not hovering, get this out of our face faster
    transition: visibility $transition-duration ease, 
                opacity $transition-duration ease,
                transform $transition-duration $easein;
    text-align: left; // overide in case text its adjusting is aligned to the left
    transform: translateY(-50%);
    max-width: 100%;
    overflow-x: auto;
  }

  .sb-inline-editor.has-content:hover .sb-display-point-edit-buttons,
  .sb-display-point-edit:hover .sb-display-point-edit-buttons,
  .sb-display-point-edit.in-configuration .sb-display-point-edit-buttons {
    transition: visibility $transition-duration ease $transition-delay, 
                opacity $transition-duration ease $transition-delay,
                transform $transition-duration $easeout $transition-delay;
    visibility: visible;
    opacity: 1;
    transform: none;
  }

  .sb-inline-editor-editing {
    .sb-display-point-edit-buttons {
      display: none;
    }
  }

  %inline-edit-button {
    display: inline-flex;
    align-items: center;
    padding: $baseline / 2;
    line-height: $baseline * 1.5;
    color: $text-color-white;
    transition: color .2s;
    .icon {
      width: .875em;
      height: .875em;
      fill: currentColor;
    }
    span {
      margin-left: .4em;
    }
  }

  .sb-display-point-edit-title {
    @extend %inline-edit-button;
  }
  .sb-display-point-edit-button {
    @extend %inline-edit-button;
    &:hover,
    &:focus {
      color: $primary-color;
      outline: none;
    }
    &.disabled,
    &[disabled] {
      opacity: .25;
      pointer-events: none;
    }
  }

  .sb-inline-editor-editing,
  .no-content,
  .sb-display-point-first {
    %edit-overlay {
      border-color: $edit-border-color;
    }
  }

  .sb-display-point-first {
    z-index: 10; //added because was sitting over configure form overlay
  }

  .sb-display-point-first-button,
  .sb-inline-editor-first-button {
    z-index: 15;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // min-height: 100%;
    // width: 100%;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    min-height: inherit;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &:hover,
    &:focus {
      color: inherit;
    }
    svg {
      width: .875em;
      height: .875em;
      fill: currentColor;
    }
    span {
      margin-left: .4em;
    }
  }
  .sb-inline-editor-spacer {
    pointer-events: none;
    visibility: hidden;
  }

  .sb-display-point-first-button {
    @extend %edit-overlay;
    &:hover {
      @extend %edit-overlay-on;
    }
  }

  .has-content .sb-inline-editor-first-button,
  .has-content .sb-inline-editor-spacer {
    display: none;
  }

  .sb-inline-editor-content textarea{
    box-shadow: none !important;
    outline: 0 !important;
    min-height: 0 !important;
    width: 100%;
    display: block;
    resize: vertical;
  }
}
@mixin inline-edit-message {
  @keyframes sb-inline-edit-message {
    from {
      transform: translateY(-50%) translateX(-50%);
      opacity: 0;
    }
    to {
      transform: translateX(-50%);
      opacity: 1;
    }
  }
  .sb-inline-edit-message {
    &.warning {
      @include input-warning($line-height: 1.5em, $icon-margin: .8em);
      color: $text-color-regular;
    }
    &.error {
      @include input-error($line-height: 1.5em, $icon-margin: .8em);
    }
    &.success {
      @include input-success($line-height: 1.5em, $icon-margin: .8em);
    }
    &.sb-inline-edit-message {
      @include typography(text-medium);
      z-index: 110;
      position: fixed;
      top: $baseline * 3;
      left: 50%;
      transform: translateX(-50%);
      background-color: $white;
      color: $text-color-regular;
      border-radius: 1.5rem;
      padding-right: .75em;
      box-shadow: 0 2px 4px rgba(0,0,0,0.2);
      overflow: hidden;
      min-height: 0;
      &::after {
          left: .4em;
      }
    }
    &:not(.fade-out) {
      animation: sb-inline-edit-message .5s cubic-bezier(0,0,0,1) both;
    }
  }
}

@include inline-edit;
@include inline-edit-message;

